import React, { useState, useEffect, useContext } from 'react';
import styles from './Assessment.module.scss';
import AssessmentProgressBar from './AssessmentProgressBar';
import AssessmentHeader from './AssessmentHeader';
import AssessmentIndustry from './AssessmentIndustry';
import AssessmentHelpWith from './AssessmentHelpWith';
import { HELP_WITH_FILTERS, INDUSTRY_MAPPINGS, LIFESTAGE_MAPPINGS } from '../Marketplace/Filters/FilterMappings';
import advisorProfileService from '../../services/advisorProfile.service';
import { Context } from '../../Utils/GlobalStore/Store';
import { UserContext } from '../../Utils/UserContext';
import useMixPanelEvent from '../../Utils/MixPanel';
import AssessmentLifeStage from './AssessmentLifeStage';
import AssessmentLocation from './AssessmentLocation';
import AssessmentAssetRange from './AssessmentAssetRange';
import AssessmentOther from './AssessmentOther';
import ConsumerLogin from './ConsumerLogin';
import AssessmentResults from './AssessmentResults';
import { useNavigate } from 'react-router';
import SEO from '../../components/SEO/seo';

function Assessment() {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [currentStage, setCurrentStage] = useState(1);
    const [state, dispatch] = useContext(Context);
    const [helpWithFilters, setHelpWithFilters] = useState(HELP_WITH_FILTERS);
    const [industryFilters, setIndustryFilters] = useState(INDUSTRY_MAPPINGS);
    const [lifeStageFilters, setLifestageFilters] = useState(LIFESTAGE_MAPPINGS);
    const [payload, setPayload] = useState({});

    const mixpanelTrack = useMixPanelEvent();

    useEffect(() => {
        const getMarketplaceMappings = async () => {
            if (state.marketplaceMappings.length <= 0) {
                const marketplaceMappings = await advisorProfileService.getMarketplaceMappings();
                dispatch({
                    type: 'SET_MARKETPLACE_MAPPINGS',
                    payload: marketplaceMappings,
                });
                setIndustryFilters(marketplaceMappings.find((config) => config.id === 'INDUSTRY_MAPPINGS').mappings);
                setLifestageFilters(marketplaceMappings.find((config) => config.id === 'LIFESTAGE_MAPPINGS').mappings);
                setHelpWithFilters(marketplaceMappings.find((config) => config.id === 'NEEDSTATE_MAPPINGS').mappings);
            } else {
                setIndustryFilters(state.marketplaceMappings.find((config) => config.id === 'INDUSTRY_MAPPINGS').mappings);
                setLifestageFilters(state.marketplaceMappings.find((config) => config.id === 'LIFESTAGE_MAPPINGS').mappings);
                setHelpWithFilters(state.marketplaceMappings.find((config) => config.id === 'NEEDSTATE_MAPPINGS').mappings);
            }
        };

        const isAdvisor = user?.groups?.includes('Advisor') || user?.groups?.includes('FirmAdmin');
        if (isAdvisor) {
            navigate(`/404`, { replace: true });
        }
        getMarketplaceMappings();
    }, [dispatch, navigate, state.marketplaceMappings, user?.groups]);

    const setAssessmentSelection = (type, value) => {
        return setPayload({ ...payload, [type]: value });
    };

    const loadStage = () => {
        switch (currentStage) {
            case 1:
                return (
                    <AssessmentHelpWith
                        mixpanelTrack={mixpanelTrack}
                        setCurrentStage={setCurrentStage}
                        helpWithFilters={helpWithFilters}
                        setAssessmentSelection={setAssessmentSelection}
                        preFill={payload}
                    />
                );
            case 2:
                return (
                    <AssessmentIndustry
                        mixpanelTrack={mixpanelTrack}
                        setCurrentStage={setCurrentStage}
                        industryFilters={industryFilters}
                        setAssessmentSelection={setAssessmentSelection}
                        preFill={payload}
                    />
                );
            case 3:
                return (
                    <AssessmentLifeStage
                        mixpanelTrack={mixpanelTrack}
                        setCurrentStage={setCurrentStage}
                        lifeStageFilters={lifeStageFilters}
                        setAssessmentSelection={setAssessmentSelection}
                        preFill={payload}
                    />
                );
            case 4:
                return <AssessmentLocation mixpanelTrack={mixpanelTrack} setCurrentStage={setCurrentStage} setAssessmentSelection={setAssessmentSelection} preFill={payload} />;
            case 5:
                return <AssessmentAssetRange mixpanelTrack={mixpanelTrack} setCurrentStage={setCurrentStage} setAssessmentSelection={setAssessmentSelection} preFill={payload} />;
            case 6:
                return <AssessmentOther mixpanelTrack={mixpanelTrack} setCurrentStage={setCurrentStage} setAssessmentSelection={setAssessmentSelection} preFill={payload} />;
            case 7:
                return <ConsumerLogin assessmentPayload={payload}></ConsumerLogin>;
            default:
                return;
        }
    };

    return (
        <>
            <SEO
                title={`Find Your Financial Advisor | AdvisorFinder Assessment`}
                description={`Find a financial advisor based on your needs with AdvisorFinder's personalized search tool. Take our six-question assessment to find personalized financial advisor results. Compare options and contact the advisor you choose today.`}
                name='AdvisorFinder'
                type='website'
                canonical={`https://advisorfinder.com/app/assessment`}
            />
            <AssessmentProgressBar currentStage={currentStage} />
            <div className={styles.pageContainer}>
                <AssessmentHeader currentStage={currentStage} />
                <div className={styles.flexContainer} style={{ marginTop: '20px' }}>
                    {loadStage()}
                </div>
            </div>
        </>
    );
}

export default Assessment;
