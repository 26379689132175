import React, { useEffect, useState } from 'react';
import styles from './MarketplaceAdvisorCard.module.scss';
import { Link } from 'react-router-dom';
import useGetProfilePicture from '../../../hooks/useGetProfilePicture';
import { MapPin, CheckCircle, XCircle, Calendar, Mail } from 'react-feather';
import Button from '../../../components/Button/Button';
import advisorProfileService from '../../../services/advisorProfile.service';
import isNotLoggedInAdvisor from '../../../Utils/isNotLoggedInAdvisor';
const classnames = require('classnames');

function MarketplaceAdvisorCard(props) {
  const [showActivateButton, setShowActivateButton] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 500);

  const {
    professionalId,
    cognitoUsername,
    firstName,
    lastName,
    displayName,
    city,
    state,
    companyName,
    jobTitle,
    yearsOfExperience,
    specialties,
    services,
    clientele,
    pricing,
    displayDistance,
    firmId,
    accountEnabled,
    hasLoggedIn
  } = props.data;
  const user = props.user;

  useEffect(() => {
    showActivateUser();
  }, [professionalId]);

  function showActivateUser() {
    if (user?.groups?.includes('FirmAdmin') && firmId === user?.firmID) {
      if (!accountEnabled || !hasLoggedIn) {
        setShowActivateButton(true);
      } else {
        setShowActivateButton(false)
      }
    } else {
      return false;
    }
  }

  const updateMedia = () => {
    setDesktop(window.innerWidth > 500);
  };

  useEffect(() => {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
  }, []);

  async function onSubmit(isEnabled) {
    setSubmitDisabled(true);
    const columnData = [
      {
        accountEnabled: isEnabled,
      },
      {
        hasLoggedIn: true, //just in case it is not
      },
    ];

    const myInit = {
      body: {
        columnData,
      },
    };

    try {
      await advisorProfileService.update(professionalId, myInit, true);
      setSubmitSuccess(true);
      setShowActivateButton(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitDisabled(false);
    }
  }

  const profilePicture = useGetProfilePicture(firmId && firmId > 0 ? professionalId : cognitoUsername);

  return (
    <>
      <Link className={styles.advisorCardContainer} to={`/advisor-profile/${professionalId}/${firstName}-${lastName}/`} target='_blank' rel='noopener noreferrer'>
        <div className={styles.advisorInfoContainer}>
          <div className={styles.profilePictureContainer}>
            <img
              fetchpriority='high'
              className={classnames(styles.profilePicture, !(profilePicture === '/app/images/profilePictureDefault.svg') ? styles.gradientBorder : styles.pupleBorder)}
              src={profilePicture ?? '/app/images/profilePictureDefault.svg'}
              alt='profile_picture'
            ></img>
          </div>
          <div className={styles.advisorInfo}>
            <h2 className={styles.location}>
              <MapPin size={10} /> {city}, {state} {displayDistance ? ` - ${displayDistance} Away` : ''}
            </h2>
            <h3>
              {displayName}
            </h3>
            <h4 className={styles.subInfo}>
              {jobTitle && <>{jobTitle} at </>}
              <b>{companyName}</b>
            </h4>
            <h5 className={classnames(styles.bottomMargin, styles.subInfo)}>
              {yearsOfExperience && (
                <>
                  Years of Experience: <b>{yearsOfExperience}</b>
                </>
              )}
            </h5>
            {isDesktop && isNotLoggedInAdvisor() && <div className={styles.cardCTAContainer}>
              <Link to={`/advisor-profile/${professionalId}/${firstName}-${lastName}/contact=true`} target='_blank' rel='noopener noreferrer' style={{marginRight:'5px'}}>
                <Button color='gradient-button' size='thin'><Mail size={20}/>Contact</Button>
              </Link>
              <Link to={`/advisor-profile/${professionalId}/${firstName}-${lastName}/scheduleCall=true`} target='_blank' rel='noopener noreferrer'>
                <Button color='clear-button' size='thin'><Calendar size={20}/>Schedule a Call</Button>
              </Link>
            </div>}
          </div>
          <div className={styles.activateContainer}>
            {showActivateButton && (
              <div>
                <Button className={styles.submitActivateButton} color="grey-button green" disabled={submitDisabled} onClick={() => onSubmit(true)}>
                <CheckCircle size={20} />Activate Advisor
                </Button>
              </div>
            )}
            {user?.groups?.includes('FirmAdmin') && firmId === user?.firmID && !showActivateButton && (
              <div>
                <Button className={styles.submitActivateButton} color="grey-button red" disabled={submitDisabled} onClick={() => onSubmit(false)}>
                <XCircle size={20} />
                  Deactivate Advisor
                </Button>
              </div>
            )}
            {submitSuccess && (
              <div>
                <CheckCircle size={16} color='var(--adfi_green500)' />
              </div>
            )}
          </div>
        </div>
        {!isDesktop && isNotLoggedInAdvisor() && <div className={styles.cardCTAContainerMobile}>
          <Link to={`/advisor-profile/${professionalId}/${firstName}-${lastName}/contact=true`} target='_blank' rel='noopener noreferrer' style={{marginRight:'5px'}}>
            <Button color='gradient-button' size='thin'><Mail size={20}/><span className='shrinkText'>Contact</span></Button>
          </Link>
          <Link to={`/advisor-profile/${professionalId}/${firstName}-${lastName}/scheduleCall=true`} target='_blank' rel='noopener noreferrer'>
            <Button color='clear-button' size='thin'><Calendar size={20}/><span className='shrinkText'>Schedule a Call</span></Button>
          </Link>
        </div>}
        <p className={styles.tagsHeader}>Specialties and Expertise</p>
        <div className={styles.tagsContainer}>
          {specialties?.slice(0, 3).map((specialty, index) => (
            <div key={index} className={styles.tag}>
              <b>{specialty.value}</b>
            </div>
          ))}
          {specialties?.length > 3 && (
            <div key={4} className={styles.tag}>
              <b>+{specialties?.length - 3}</b>
            </div>
          )}
        </div>
        <p className={styles.tagsHeader}>Typical Clientele</p>
        <div className={styles.tagsContainer}>
          {clientele?.slice(0, 3).map((client, index) => (
            <div key={index} className={styles.tag}>
              <b>{client.value}</b>
            </div>
          ))}
          {clientele?.length > 3 && (
            <div key={4} className={styles.tag}>
              <b>+{clientele?.length - 3}</b>
            </div>
          )}
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.footerSection}>
            <div>Services Provided</div>
            <ul>
              {services?.slice(0, 3).map((price, index) => (
                <li key={index}>
                  {' '}
                  <b>{price.value}</b>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.footerSection}>
            <div>Pricing Models</div>
            <ul>
              {pricing?.slice(0, 3).map((range, index) => (
                <li key={index}>
                  {' '}
                  <b>{range.value}</b>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Link>
    </>
  );
}

export default MarketplaceAdvisorCard;
